import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">

      <Container>
        <div className="text-center">

          <h6 className="main-heading"><span className="bluecolor">FAQ</span></h6>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is a cryptocurrency wallet, and why is it necessary to have one?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">A cryptocurrency wallet is a digital tool for securely storing, sending, and receiving cryptocurrencies. You need one to manage your digital assets safely and efficiently.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How to approach Crypto Wallet App Development for my business?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Start by defining your requirements and selecting a development team with expertise in blockchain technology. Plan your features, design the user interface, and ensure robust security measures.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How can you ensure the crypto wallet security and the safety of data it stores?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Implement strong encryption, multi-signature authentication, and regular security audits to protect data. Use advanced security protocols and compliance measures to safeguard user information.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What is the cost of cryptocurrency wallet development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The cost varies based on features, complexity, and customization. Basic wallets might start at a few thousand dollars, while advanced solutions can range significantly higher.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How long does it take to implement a crypto wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The development timeline depends on the wallet’s complexity and features. Typically, it can take from a few weeks to several months to complete and deploy a fully functional wallet.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection