import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
              <div className="innerContent">
                <h1 className="main-heading"><span className='bluecolor'>Cryptocurrency</span> Wallet Development Company</h1>
                <p className="pharagraph">Elevate your entry into the digital currency space with our specialized cryptocurrency wallet development services. We create a secure cryptocurrency wallet app with essential features to enhance your user’s crypto experience. Our team of experts provides all types of wallets that meet your exact needs.</p>
                <p className="pharagraph">Get connected with us and explore how we can turn your vision into reality.</p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/crypto-wallet/cryptocurrency-wallet-development-company.webp"
                  alt="Cryptocurrency Wallet Development Company banner"
                  width={743}
                  className='d-none d-lg-block'
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection