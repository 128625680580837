
import React from 'react'



const DevApproach = () => {


  return (
    <section className="customs wall-pro pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h3 className="heading-h2 text-center"><span className='d-block'><span className="bluecolor">Development process</span> of</span> cryptocurrency wallet development services</h3>
            <p className='text-center'>Our streamlined development approach ensures a high-quality, customized wallet solution that meets your specific needs.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <p className="head3">Requirement Gathering</p>
                    <p class="pharagraph">The first step in our development process involves thorough requirement gathering. Our team of experts engages with you to understand your business objectives ensuring that we propose the most effective solution tailored to your requirements.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Planning</p>
                    <p class="pharagraph">We meticulously plan the development of your crypto wallet by creating a detailed roadmap. This structured approach helps us execute the project efficiently and effectively, adhering to your specifications and ensuring a smooth development process.</p>
                </div>
                <div className='custom-blk'> 
                    <p className="head3">UI/UX Designing</p>
                    <p class="pharagraph">At Coinsclone, our talented design team crafts user-friendly and aesthetically pleasing interfaces. We prioritize client satisfaction by iterating on design concepts until the UI/UX meets your expectations, ensuring a seamless and engaging user experience.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Development</p>
                    <p class="pharagraph">During the development phase, our skilled cryptocurrency wallet developers focus on building and optimizing the backend. We incorporate the latest functionalities and security mechanisms to ensure your wallet is both cutting-edge and secure.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Testing</p>
                    <p class="pharagraph">Our dedicated QA team conducts rigorous and thorough testing of your blockchain wallet to ensure exceptional quality. We perform multiple rounds of testing to identify and resolve any issues, delivering a flawless product.</p>
                </div>
                <div className='custom-blk'>
                    <p className="head3">Deployment</p>
                    <p class="pharagraph">After successful testing, we securely deploy your wallet application or web extension to your preferred server. Our team ensures a smooth and swift launch, providing you with a fully functional and reliable crypto wallet.</p>
                </div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach