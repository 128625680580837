import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="binancestack dev-tech-stack exchange-stack">
      <div className="container">
        <div className="row table-content">
          <div className="col-md-12 col-lg-6">
            <h5 className="heading-h2"><span className="bluecolor">Technologies Used</span> For our Cryptocurrency Wallet Development</h5>
            <p className="pharagraph">We have a group of seasoned crypto wallet developers to create a cryptocurrency wallet using trending technological stacks.
            </p>
          </div>
          <div className="col-md-12 col-lg-6">
          <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                alt="Technologies stock"
                className='logostck'
                width={573}
              />
          </div>
        </div>
      </div>
    </section>
    </div>
    )
  }
}

export default TechStack             